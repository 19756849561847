import { Authing } from "@authing/browser";
import { AuthingConf } from "@app/shared";

export const auth = new Authing({
  appId: AuthingConf.appId,
  domain: "https://6pen.authing.cn",
  redirectUri: typeof window === "undefined" ? "" : `${window.location.origin}/extension_login`,
  logoutRedirectUri: typeof window === "undefined" ? "" : window.location.origin,
  // useImplicitMode: import.meta.env.MODE === "development",
  useImplicitMode: (typeof chrome !== "undefined" && !!chrome.storage) || window.location.protocol === "http:",
});
