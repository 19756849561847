import React from "react";
import "./index.css";
import Router from "./router";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { QueryClientProvider } from "react-query";

import { ThemeProvider } from "../../../packages/browser";
import { useTheme, Box } from "@mui/material";
import { queryClient } from "@app/api";
import { Toaster } from "@app/browser/hooks";
import "@app/browser/i18n";

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container);

const App = () => {
  const theme = useTheme();
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Box
          sx={{
            color: "text.primary",
            minHeight: "100vh",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Toaster />
          <Router />
        </Box>
      </QueryClientProvider>
    </RecoilRoot>
  );
};
root.render(
  <ThemeProvider prefersDarkMode={false}>
    <App />
  </ThemeProvider>,
);
