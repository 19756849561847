var PAY_APP_ID = "250751622157428";
var APP_NAME = "pixeldots";
// export const Captalize_APP_NAME = APP_NAME.slice(0, 1).toUpperCase() + APP_NAME.slice(1);
var Captalize_APP_NAME = "PixelDots";
var APP_SLOGAN = "使用文本描述生成图片";
var DEV_FRONT_PORT = 5173;
var DEV_FRONT_ORIGIN = "http://192.168.123.88";
var TEST_FRONT_ORIGIN = "https://test.pixeldots.art";
var TEST_BACKEND_ORIGIN = "https://testapi.pixeldots.art";
var PROD_FRONT_ORIGIN = "https://pixeldots.art";
var PROD_BACKEND_ORIGIN = "https://api.pixeldots.art";
var FRONT_LOCAL_ORIGIN = "http://localhost:".concat(DEV_FRONT_PORT);
var DEV_FRONT_DOMAIN = "".concat(DEV_FRONT_ORIGIN, ":").concat(DEV_FRONT_PORT);
var DEV_API_PORT = 4008;
var TEST_API_PORT = 4009;
var PROD_API_PORT = 4010;
var DEV_API_URL = "".concat(DEV_FRONT_ORIGIN, ":").concat(DEV_API_PORT, "/api/");
var TEST_API_URL = "".concat(TEST_BACKEND_ORIGIN, "/");
var PROD_API_URL = "".concat(PROD_BACKEND_ORIGIN, "/");
var ENVS = {
  dev: {
    API_URL: DEV_API_URL,
    FRONT_DOMAIN: DEV_FRONT_DOMAIN,
    API_PORT: DEV_API_PORT
  },
  test: {
    API_URL: TEST_API_URL,
    FRONT_DOMAIN: TEST_FRONT_ORIGIN,
    API_PORT: TEST_API_PORT
  },
  production: {
    API_URL: PROD_API_URL,
    FRONT_DOMAIN: PROD_FRONT_ORIGIN,
    API_PORT: PROD_API_PORT
  }
};

// export const QuestionsDoc = 'https://maoxianqiu.feishu.cn/wiki/wikcnkHmtEPr3YN0Ocac4ynSs5g?from=auth_notice&hash=3f35d8e4b8072fd52716ffc4abc64399'

export { APP_NAME, APP_SLOGAN, Captalize_APP_NAME, DEV_API_URL, DEV_FRONT_DOMAIN, DEV_FRONT_ORIGIN, ENVS, FRONT_LOCAL_ORIGIN, PAY_APP_ID, PROD_API_URL, TEST_API_URL };
