import React from "react";
import { Box, Button, Typography, Link, Container } from "@mui/material";

import i18n from "@app/browser/i18n";
const Home = () => {
  return (
    <Box className="bg-[#2f2f2f]">
      <Container
        sx={{
          height: "100vh",
          display: "flex",
        }}
        maxWidth="xl"
        className="relative overflow-hidden justify-between items-center"
      >
        <Box sx={{ mr: 10 }}>
          <img className="h-8 -ml-4" src="/images/full-logo_dark.png" alt="" />
          <Typography
            sx={{
              mt: 4,
              lineHeight: 1.2,
            }}
            className="text-white"
            fontSize={48}
            fontWeight={500}
          >
            {i18n.t("slogan", {
              ns: "common",
            })}
          </Typography>
          <Typography sx={{ mb: 10, mt: 3 }} className="text-white opacity-75" fontSize={20} lineHeight={1.4}>
            {i18n.t("sloganDescription", {
              ns: "common",
            })}
          </Typography>

          <Link
            target="_blank"
            href="https://chrome.google.com/webstore/detail/pixeldots/omlfhbpoklehmlegdmfdmcckomkfpljd"
            sx={{
              textDecoration: "none",
            }}
          >
            <Button sx={{ mt: 2, width: 160, height: 48 }} variant="contained">
              <img className="w-[18px] mr-2" src="/images/chrome.svg" alt="" />
              {i18n.t("addTo", {
                ns: "common",
              })}{" "}
              Chrome
            </Button>
          </Link>
          <Link
            target="_blank"
            sx={{
              textDecoration: "none",
            }}
            href="https://microsoftedge.microsoft.com/addons/detail/pixeldots/pjbjknhdpdhoppgjekbhnamdimdabgoa"
          >
            <Button sx={{ mt: 2, mx: 2, width: 160, height: 48 }} variant="contained">
              <img className="w-4 mr-2" src="/images/edge.svg" alt="" />
              {i18n.t("addTo", {
                ns: "common",
              })}{" "}
              Edge
            </Button>
          </Link>

          {/* <Button sx={{ mt: 2, width: 160, height: 48 }} variant="contained">
            <img className="w-4 mr-2" src="/images/firefox.svg" alt="" />
            {i18n.t("addTo", {
              ns: "common",
            })}{" "}
            Firefox
          </Button> */}
        </Box>
        <img className="w-[50%] rounded-lg" src="/images/frame_generic_dark.png" alt="" />
      </Container>
    </Box>
  );
};

export default Home;
