import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useFetchUserInfo } from "@/hooks/useFetchUserInfo";
import { useFetchLoginState } from "@/hooks/useFetchLoginState";
import { CircularProgress, Box } from "@mui/material";
export const AuthRoute = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useFetchLoginState();
  const { data: userInfo, isLoading: fetchingUserInfo } = useFetchUserInfo();
  const location = useLocation();

  if (isLoading || fetchingUserInfo)
    return (
      <Box className="flex items-center justify-center h-screen w-screen">
        <CircularProgress size={24} />
      </Box>
    );
  if (!userInfo) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return <>{children}</>;
};
