import { auth, useToast } from "@app/browser";
import { useQuery } from "react-query";
import { useFetchLoginState } from "./useFetchLoginState";
import { sleep } from "@app/shared";
export function useFetchUserInfo() {
  const { data } = useFetchLoginState();
  const { showToast } = useToast();
  return useQuery({
    queryKey: ["fetchUserInfo"],
    enabled: !!data,
    queryFn: async () => {
      const loginState = await auth.getLoginState();
      const userInfo = await auth.getUserInfo({
        accessToken: loginState?.accessToken,
      });
      return userInfo;
    },
    onError: async () => {
      showToast("获取用户信息失败");
      await sleep(2000);
      auth.logoutWithRedirect();
    },
  });
}
