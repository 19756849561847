import React, { useMemo } from "react";
import { ThemeProvider as MuiThemeProvider, darken, rgbToHex } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";
import { purple, grey, red } from "@mui/material/colors";

export const generateTheme = ({
  htmlFontSize,
  prefersDarkMode,
}: {
  htmlFontSize?: number;
  prefersDarkMode: boolean;
}) => {
  return createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: prefersDarkMode ? rgbToHex(darken("#11a19d", 0.12)) : "#11a19d",
        contrastText: "#fff",
      },
      error: {
        dark: red[300],
        light: red[400],
        main: prefersDarkMode ? red[300] : red[400],
      },
      secondary: {
        main: purple[400],
      },
      text: {
        primary: prefersDarkMode ? grey[300] : "#424359",
        secondary: prefersDarkMode ? grey[500] : grey[500],
      },
      background: prefersDarkMode
        ? {
            default: "#2f2f2f",
            paper: "#383838",
          }
        : {
            default: "#f8f8f8",
            paper: "#fff",
          },
    },
    typography: {
      htmlFontSize,

      body1: {
        fontSize: "14px",
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 12,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: "none",
          },
        },
        defaultProps: {
          disableElevation: true,
          sx: {
            textTransform: "unset",
          },
        },
      },
    },
  });
};
const ThemeProvider: React.FC<{ children?: React.ReactNode; prefersDarkMode?: boolean; htmlFontSize?: number }> = ({
  children,
  prefersDarkMode = false,
  htmlFontSize,
}) => {
  const theme = useMemo(() => {
    return generateTheme({ prefersDarkMode, htmlFontSize });
  }, [prefersDarkMode, htmlFontSize]);
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
