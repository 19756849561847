import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/es.object.to-string.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/es.promise.constructor.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/es.promise.all.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/es.promise.catch.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/es.promise.race.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/es.promise.reject.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/es.promise.resolve.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/web.set-interval.js';
import '../node_modules/.pnpm/core-js@3.26.0/node_modules/core-js/modules/web.set-timeout.js';

function sleep() {
  var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2000;
  return new Promise(function (resolve) {
    setTimeout(resolve, duration);
  });
}

export { sleep };
