import { auth } from "@app/browser";
import { LoginState } from "@authing/browser/dist/types/global";
import { useQuery } from "react-query";

export const fetchLoginState = async () => {
  let _loginState: LoginState | null = null;
  if (auth.isRedirectCallback()) {
    _loginState = await auth.handleRedirectCallback();
    window.history.replaceState(null, "", " ");
  } else {
    _loginState = await auth.getLoginState();
  }
  return _loginState;
};
export function useFetchLoginState() {
  return useQuery({
    queryKey: ["fetchLoginState"],
    queryFn: fetchLoginState,
  });
}
