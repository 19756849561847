import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthRoute } from "./auth";
import { ErrorBoundary } from "@/components";
import { CircularProgress } from "@mui/material";
// import Doc from "@/views/doc";
// const Docs = React.lazy(() => import("@/views/docs"));
import Home from "@/views/home";
// import Logo from "@/components/Logo";
import { Captalize_APP_NAME } from "@app/shared";
import i18n from "@app/browser/i18n";
const Login = React.lazy(() => import("@/views/login"));
const ExtensionLogin = React.lazy(() => import("@/views/login/extension"));

const Gallery = React.lazy(() => import("@/views/gallery"));
const NotFound = React.lazy(() => import("@/views/404"));
const Pay = React.lazy(() => import("@/views/pay"));
const CheckPayment = React.lazy(() => import("@/views/pay/check"));
const Upgrade = React.lazy(() => import("@/views/upgrade"));

const LinearProgress = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      {/* <Logo /> */}
      <CircularProgress />
    </div>
  );
};

const InternalRoute: React.FC<{ title: string; children: React.ReactNode }> = ({ children, title, ...rest }) => {
  useEffect(() => {
    document.title = `${Captalize_APP_NAME} - ${title}`;
  }, [title]);
  return <>{children}</>;
};
const RouterView = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <BrowserRouter basename="/">
        <ErrorBoundary hasError={false}>
          <Routes>
            {/* <Route
              path="/doc/:id"
              element={
                <AuthRoute>
                  <InternalRoute title="文章">
                    <Doc />
                  </InternalRoute>
                </AuthRoute>
              }
            /> */}
            {/* <Route
              path="/docs"
              element={
                <AuthRoute>
                  <InternalRoute title="列表">
                    <Docs />
                  </InternalRoute>
                </AuthRoute>
              }
            /> */}
            <Route
              path="/gallery"
              element={
                <AuthRoute>
                  <InternalRoute
                    title={i18n.t("gallery", {
                      ns: "gallery",
                    })}
                  >
                    <Gallery />
                  </InternalRoute>
                </AuthRoute>
              }
            />
            <Route
              path="/"
              element={
                <InternalRoute
                  title={i18n.t("home", {
                    ns: "common",
                  })}
                >
                  <Home />
                </InternalRoute>
              }
            />
            <Route
              path="/login"
              element={
                <InternalRoute
                  title={i18n.t("login", {
                    ns: "common",
                  })}
                >
                  <Login />
                </InternalRoute>
              }
            />
            <Route
              path="/extension_login"
              element={
                <InternalRoute
                  title={i18n.t("login", {
                    ns: "common",
                  })}
                >
                  <ExtensionLogin />
                </InternalRoute>
              }
            />

            <Route
              path="/pay"
              element={
                <InternalRoute
                  title={i18n.t("payment", {
                    ns: "membership",
                  })}
                >
                  <Pay />
                </InternalRoute>
              }
            />
            <Route
              path="/upgrade"
              element={
                <InternalRoute
                  title={`${i18n.t("renew", {
                    ns: "membership",
                  })}/${i18n.t("upgrade", {
                    ns: "membership",
                  })}`}
                >
                  <Upgrade />
                </InternalRoute>
              }
            />
            <Route
              path="/check_payment"
              element={
                <InternalRoute
                  title={i18n.t("paymentResult", {
                    ns: "bill",
                  })}
                >
                  <CheckPayment />
                </InternalRoute>
              }
            />

            <Route
              path="*"
              element={
                <InternalRoute title="404">
                  <NotFound />
                </InternalRoute>
              }
            />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
};

export default RouterView;
