import login from "./login.json";
import common from "./common.json";
import gallery from "./gallery.json";
import membership from "./membership.json";
import panel from "./panel.json";
import bill from "./bill.json";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  common,
  gallery,
  membership,
  panel,
  bill,
};
